/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Innerspace = {
    // All pages
    'common': {
      init: function() {

        // Mobile navigation toggle.
        function nav_toggle() {

          var mobile_link = $('.navbar-toggle');
          var nav = $(mobile_link).data('target');

          // .on() requires at least jQuery 1.7.
          $(mobile_link).on('click', function(e){
            e.preventDefault();
            $(nav).toggleClass('expanded');
          });

        }

        nav_toggle();

        // If device is touch enabled...
        if(Modernizr.touch) {
          $(".call__number").each(function () {
            // wrap phone no. with a tel://
            var PhoneNumber = $(this).text();
            $(this).wrapInner('<a class="call__number-link" href=""></a>');
            $('.call__number-link').attr('href', 'tel:'+PhoneNumber);
          });
        }

        // Lighbox
        $('.lightbox-image').magnificPopup({
            type:'image',
            mainClass: 'mfp-with-zoom',

            zoom: {
              enabled: true,

              duration: 300,
              easing: 'ease-in-out',

              opener: function(openerElement) {
                return openerElement.is('img') ? openerElement : openerElement.find('img');
              }
            }
          }
        );

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    'contact': {
        init: function () {
            $('#input_1_4').change(function() {
               if ($(this).val() !== 'VIC' && $(this).val() !== 'WA' && $(this).val() !== 'TAS') {
                   $.featherlight($('#state-warning'), {});
               }
            });
        }
    },
    'appointment': {
        init: function () {
            $('#input_1_4').change(function() {
                if ($(this).val() !== 'VIC' && $(this).val() !== 'WA' && $(this).val() !== 'TAS') {
                    $.featherlight($('#state-warning'), {});
                }
            });
        }
    },
    'quote': {
        init: function () {
            $('#input_1_4').change(function() {
                if ($(this).val() !== 'VIC' && $(this).val() !== 'WA' && $(this).val() !== 'TAS') {
                    $.featherlight($('#state-warning'), {});
                }
            });
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Innerspace;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
